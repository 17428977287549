import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useCallback, useState } from "react"
import logo from '../images/logo.png'
import pdLogo from '../images/pd-logo.png'
import explorerLogo from '../images/explorer-logo.svg'

const getSelectorText = (page) => {
  switch (page) {
    case 'pd':
      return 'For PD';
    case 'tutors': 
      return 'For Tutors';
    case 'schools': 
      return 'For Schools';
    case 'curriculum': 
      return 'Curriculum Publishers';
    case 'higher-ed': 
      return 'Higher Education';
    default:
      return 'For PD';
  }
}

const getLogo = (page) => {
  switch (page) {
    case 'pd':
      return pdLogo;
    case 'explorer':
      return explorerLogo;
    default:
      return logo;  
  }
}

const Header = ({ color, page }) => {
  const [showAddButton, setShowAddButton] = useState(false)
  const handleScroll = useCallback(() => {
    if (window.scrollY > 200) {
      setShowAddButton(true)
    } else {
      setShowAddButton(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header style={{
      background: color, 
      padding: page === 'explorer' ? '30px 120px' : '16px 120px', 
      backdropFilter: page === 'explorer' ? 'blur(20px)': 'none',
      height: page === 'explorer' ? 104 : 'auto'
    }}>
      <div className="left-section">
        <Link to="/">
          <img src={getLogo(page)} height={28} />
          {
            page === 'explorer' ?
            <span className="logo-text">Explore</span> :
            null
          }
        </Link>
        {/* {
          page &&
          <div className="page-selector-container">
            <div className="selector">
              <span>{getSelectorText(page)}</span>
              <i className="material-icons-outlined">keyboard_arrow_down</i>
            </div>
            <ul>
              <li><Link to="/pd">For PD</Link></li>
              <li><Link to="/tutors">For Tutors</Link></li>
              <li><Link to="/schools">For Schools</Link></li>
              <li><Link to="/curriculum">Curriculum Publishers</Link></li>
              <li><Link to="/higher-ed">Higher Education</Link></li>
            </ul>
          </div>
        } */}
      </div>
      {
        page !== 'explorer' ?
        <div className="right-section">
          <a className="btn-login" href="https://my.pencilapp.com">
            Login
            <i className="material-icons-outlined">person_outline</i>
          </a>
          <a 
            className="btn-book" 
            target="_blank"
            href="https://try.pencilapp.com/pencilapp-demo/" 
          >
            Book a demo
            <i className="material-icons-outlined">arrow_forward</i>
          </a>
          {/* <button className="btn-menu">
            <i className="material-icons-outlined">menu</i>
          </button> */}
        </div> :
        showAddButton ?
        <button className="btn-add-chrome desktop"><strong>Add to Chrome</strong> it's free</button> : null
      }
      <button className="btn-add-chrome mobile"><strong>Send link</strong> for later</button>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
