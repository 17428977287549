/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsent from 'react-cookie-consent';

import Header from "./Header"
import Footer from "./Footer"

import "./layout.css"
import { Link } from "gatsby";

const Layout = ({ children, color, page }) => {
  const location = useLocation();
  useEffect(() => {
    initializeAndTrack(location);
  }, []);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton={true}
      >
        This site uses cookies to give you the best experience  ...
        <Link to="/cookiepolicy" className="btn-learn-more">Learn more</Link>
      </CookieConsent>
      <Header color={color} page={page} />
      <main className={`${page}-page`}>{children}</main>
      <Footer page={page} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
