import { Link } from "gatsby"
import React from "react"
import logo from '../images/footer-logo.png'
import border from '../images/borders/border-gray.svg'
import img1 from '../images/footer-img1.svg'
import img2 from '../images/footer-img2.svg'
import img3 from '../images/footer-img3.svg'
import s8img1 from '../images/section8-img1.svg';
import s8img3 from '../images/section8-img3.png';
import s8img4 from '../images/section8-img4.png';
import explorerLogo from '../images/explorer-logo.svg'

const getSelectorText = (page) => {
  switch (page) {
    case 'pd':
      return 'For PD';
    case 'tutors': 
      return 'For Tutors';
    case 'schools': 
      return 'For Schools';
    case 'curriculum': 
      return 'Curriculum Publishers';
    case 'higher-ed': 
      return 'Higher Education';
    default:
      return 'For PD';
  }
}

const Footer = ({ page }) => (
  <>
    {
      page !== 'explorer' ?
      <>
        <img src={border} className="section-border" style={{position: 'relative', top: -15}} />
        <section className="book-section">
          <div className="left-section">
            <h2 className="feedback-title">Ready to see more?</h2>
            <a 
              className="btn-signup" 
              target="_blank"
              href="https://try.pencilapp.com/pencilapp-demo/" 
            >
              Book a demo <i className="material-icons-outlined">arrow_forward</i>
            </a>
          </div>
          <div className="right-section">
            <img className="img1" src={s8img1} />
            <img className="img3" src={s8img3} />
            <img className="img4" src={s8img4} />
          </div>
        </section>
      </> :
      null
    }
    <footer>
      <img src={border} className="section-border" />
      <img src={img1} className="img1" />
      <div className="content">
        <div className="copyright-section">
          <div className="logo-container">
            <img src={explorerLogo} className="logo" />
            {/* {
              page &&
              <span>{getSelectorText(page)}</span>
            } */}
            <Link to="/tos">Terms of Service</Link>
            <div className="divider"></div>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
          <p>Copyright © 2020 Pencil Learning Technologies</p>
          <p>San Francisco, CA 94104</p>
          <p>All rights reserved.</p>
        </div>
      </div>
      <img src={img2} className="img2" />
      <img src={img3} className="img3" />
    </footer>
  </>
)

export default Footer
